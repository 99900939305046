import * as React from "react";
import { Link } from "gatsby";

import Bridge from "../../components/Bridge.js";
// import Ch from "../../components/Chord.js";
import Chorus from "../../components/Chorus.js";
import Columns from "../../components/Columns.js";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";
import Verse from "../../components/Verse.js";

export default function JustTheTwoOfUs() {
  return (
    <Layout>
      <Helmet title="Just The Two Of Us | Bill Withers" />

      <h1>Just The Two Of Us</h1>

      <h2>Bill Withers</h2>

      <h4>Key: G</h4>

      <h4>Tempo: 92</h4>

      <Link to="/">List</Link>

      <Columns columns="2">
        <Verse>
          <p>I see the crystal raindrops fall</p>
          <p>And the beauty of it all</p>
          <p>Is when the sun comes shining through</p>
        </Verse>
        <Verse>
          <p>To make those rainbows in my mind</p>
          <p>When I think of you sometime</p>
          <p>And I wanna spend some time with you</p>
        </Verse>
        <Chorus>
          <p>Just the two of us</p>
          <p>We can make it if we try</p>
          <p>Just the two of us (Just the two of us)</p>
          <p>Just the two of us</p>
          <p>Building castles in the sky</p>
          <p>Just the two of us</p>
          <p>You and I</p>
        </Chorus>
        <Verse>
          <p>We look for love, no time for tears</p>
          <p>Wasted water's all that is</p>
          <p>And it don't make no flowers grow</p>
        </Verse>
        <Verse>
          <p>Good things might come to those who wait</p>
          <p>Not for those who wait too late</p>
          <p>We gotta go for all we know</p>
        </Verse>
        <Chorus>
          <p>Just the two of us</p>
          <p>We can make it if we try</p>
          <p>Just the two of us (Just the two of us)</p>
          <p>Just the two of us</p>
          <p>Building castles in the sky</p>
          <p>Just the two of us</p>
          <p>You and I</p>
        </Chorus>
        <Bridge>
          <p>Very Crazy CHORDS</p>
        </Bridge>
        <Verse>
          <p>I hear the crystal raindrops fall</p>
          <p>On the window down the hall</p>
          <p>And it becomes the morning dew</p>
        </Verse>
        <Verse>
          <p>And darling when the morning comes</p>
          <p>And I see the morning sun</p>
          <p>I wanna be the one with you</p>
        </Verse>
        <Chorus>
          <p>Just the two of us</p>
          <p>We can make it if we try</p>
          <p>Just the two of us (Just the two of us)</p>
          <p>Just the two of us</p>
          <p>Building castles in the sky</p>
          <p>Just the two of us</p>
          <p>You and I</p>
        </Chorus>
        <Chorus>
          <p>Just the two of us</p>
          <p>We can make it if we try</p>
          <p>Just the two of us (Just the two of us)</p>
          <p>Just the two of us</p>
          <p>Building castles in the sky</p>
          <p>Just the two of us</p>
          <p>You and I</p>
        </Chorus>
      </Columns>
    </Layout>
  );
}
